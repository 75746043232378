import React from 'react';
import CurriculumCard from '../components/CurriculumCard';
import { Column, Container, Row } from '../components/Grid';
import Icon from '../components/Icon';
import Image from '../components/Image';
import Layout from '../components/Layout';
import Section from '../components/Section';
import Video from '../components/Video';

const IndexPage = ({ data }) => (
  <Layout title="Home">
    <Section>
      <Image className="index-header" filename="elevation-bg.svg" />
      {/* <Section>
        <Container>
          <Row>
            <Column size={8}>
              <Video
                poster="forces-of-motion-placeholder.png"
                guidSrc="5755f367-37b2-4aa0-a9ec-71488f8443e1"
                title=""
                autoplay
              />
            </Column>
            <Column size={4}>
              <h2>Discover a world of STEM possibilities</h2>
              <p>
                Explore standards-aligned resources, dynamic videos, and
                hands-on activities with Innovation at Play. Whether it's in the
                classroom or afterschool, you'll ignite their curiosity and
                prepare them for the careers of tomorrow.
              </p>
            </Column>
          </Row>
        </Container>
      </Section> */}
      <Container>
        <CurriculumCard
          image="home-image-AI-masterclass.png"
          title="AI Revolution: Linking Learners to Workforce"
          description="Are you ready to prepare students for the demands of tomorrow's workforce? In this thought-provoking Master Class, we delve into the transformative potential of AI technology, reshaping the future of work and empowering the classrooms of today. "
          buttonUrl="/curriculum-resources/ai-revolution"
        />
        <CurriculumCard
          rtl
          image="home-image-energyandforces@2x.png"
          title="Relationship Between Energy and Forces"
          description="Forces—gravitational, magnetic, and electrical, for instance—transmit energy from one object to another. Students discover how these forces can be harnessed to create sensors and virtual and mixed reality experiences, and help us interact with the world in new and powerful ways."
          buttonUrl="/curriculum-resources/energy-and-forces"
        />
        <CurriculumCard
          image="home-image-conservationofenergy@2x.png"
          title="Conservation of Energy & Energy Transformation"
          description="Students learn about the processes of conservation of energy and the constant energy transformations happening all the time in their everyday lives. Energy transformation is examined in machines, natural phenomena, and without our own bodies. "
          buttonUrl="/curriculum-resources/energy-and-energy-transfer"
        />
        <CurriculumCard
          rtl
          image="home-image-FAM.png"
          title="Forces and Motion"
          description="Give students the tools to tinker with their world and imagine
          their own life-changing solutions with an interactive exploration
          of the Laws of Motion. Ignite student creativity with a topic
          series video and hands-on STEM activations that connect essential
          laws of motion to innovations in AI, automation, robotics and
          more."
          buttonUrl="/curriculum-resources/forces-of-motion"
        />
        <CurriculumCard
          image="home-image-stability.png"
          title="Stability and Instability"
          description="Allow students to examine the complex ways that the human body
          self-regulates to maintain stability while interacting with the forces of
          gravity, and find out the difference between center of gravity and center of
          mass. "
          buttonUrl="/curriculum-resources/stability-and-instability"
        />
        <CurriculumCard
          rtl
          image="home-image-interactions.png"
          title="Types of Interactions"
          description="Inspire students to interact with their environment,
          investigate important challenges, and create original solutions by tapping
          into the universal laws of nature. Watch the topic series video to learn how
          students can harness the Laws of Motion to ignite innovation and pursue
          exciting STEM careers emerging in the Industry 4.0 revolution."
          buttonUrl="/curriculum-resources/types-of-interactions"
        />
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <Column size={12} className="index-spark-ideas">
            <Image
              filename="home-graphic-ellipse-STAT.svg"
              className="yellow-ellipse"
            />
            <Image
              filename="home-graphic-wavylines-STAT.svg"
              className="spark-bg"
            />
            <Image filename="home-image-STAT.png" className="spark-boy" />
            <h2 className="spark-title">Spark Ideas Into Action</h2>
            <p className="spark-text">
              Find fun ways to prepare students for the careers of tomorrow
              through project-based STEM challenges that can be implemented on
              simple terms.
            </p>
          </Column>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <Column size={3}>
            <h2>About Innovation at Play</h2>
            <p>
              Caterpillar Foundation, Learning Undefeated, and Discovery
              Education are providing educators, students, and families
              nationwide with a digital sandbox to explore the building blocks
              STEM problem-solving and apply these skills to create real-world
              change. Innovation At Play fosters hands-on experiential learning
              through student activations that are aligned to the future of
              STEM.
            </p>
            <a href="/about">
              <Icon name="externallink" marginRight /> Learn more
            </a>
          </Column>
          <Column size={1}></Column>
          <Column size={8} className="home-modules__image-column">
            <Image
              filename="about-innovation.png"
              className="home-modules__img"
            />
          </Column>
        </Row>
      </Container>
    </Section>
    <Section>
      <Container>
        <Row>
          <Column size={12} className="footer-image-column home">
            <div className="text-overlay">
              <h2>
                STEM has been recommended as an effective approach and
                alternative to stimulating students' creative thinking
                abilities.
              </h2>
              <h3>– Journal of Physics</h3>
            </div>
          </Column>
        </Row>
      </Container>
    </Section>
  </Layout>
);

export default IndexPage;
