import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import Button from '../Button';
import Icon from '../Icon';
import Image from '../Image';
import './styles.scss';

const CurriculumCard = ({ image, title, description, buttonUrl, rtl }) => {
  return (
    <div
      className={classNames('curriculum-card', rtl && 'curriculum-card--rtl')}
    >
      <div className="curriculum-card__small-column">
        <Image className="curriculum-card__image" filename={image} />
      </div>
      <div className="curriculum-card__big-column">
        <div className="curriculum-card__text">
          <h2>{title}</h2>
          <p>{description}</p>
          <Button
            to={buttonUrl}
            className="curriculum-card__button forces-button"
          >
            <Icon name="externallink" marginRight /> Learn more
          </Button>
        </div>
      </div>
    </div>
  );
};

Button.propTypes = {
  /** A path to the image */
  image: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  /** Path to the Curriculum page */
  buttonUrl: PropTypes.string,

  /** If false, image will be on the right and text on the left.
   * If true, the image will be on the left and the text on the right */
  rtl: PropTypes.bool,
};

export default CurriculumCard;
